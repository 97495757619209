<template>
  <div class="useGuide">
     <!-- //上面模块简介 -->
     <div class="oneBox">
           <h4>简要指引</h4>
           <p>开始直播： 开启直播工具“ OBS Studio ” -- 【设置】 -- 【推流】 -- 【服务】 -- 【自定义】 -- 复制服务器信息(本平台直播详情页）-- 黏贴服务器 -- 复制串流密钥</p>
           <p>信息(本平台直播详情页）-- 黏贴串流密钥 -- 【确定】 -- 【开始推流】 -- 【进入教研直播】(本平台直播详情页）-- 直播开始。</p>
           <p>结束直播： 【结束推流】(直播工具）-- 【结束直播】(本平台直播间）--  直播结束。</p>
     </div>
     <div class="TwoBox">
          <h4>详细指引</h4>
          <p style="margin-top:10px">开始直播:</p>
          <!-- 标识 -->
          <div class="listBox">
               <p>
                 ①开启直播工具“ OBS Studio ”，开启后点击左下角【设置】，进入设置页面。
               </p>
               <div>
                 <img src="../../assets/images/guide/use/1.png" alt="">
               </div>
          </div>
          
          <div class="listBox">
               <p>
                ② 在设置页面左侧，点击【推流】。在推流页面，【服务】下拉框选择【自定义】。
               </p>
               <div>
                 <img src="../../assets/images/guide/use/2.png" alt="">
               </div>
          </div>


          <div class="listBox">
               <p>
                 ③【服务器】、【串流密钥】由本平台提供。您可在本次直播详情页找到，点击后方的复制按钮，将其黏贴在推流页面对应的输入框中。<br/>（注意：不同直播对应不同的服务器和串流密钥，请在您需要开展的直播的详情页找到该直播的服务器和串流密钥。）
               </p>
               <div>
                 <img src="../../assets/images/guide/use/3.1.png" alt="">
                  <img src="../../assets/images/guide/use/3.2.png" alt="">
               </div>
          </div>


          <div class="listBox">
               <p>
                 ④点击【确定】完成本次直播地址的设置。
               </p>
               <div>
                  <img src="../../assets/images/guide/use/4.png" alt="">
               </div>
          </div>



          <div class="listBox">
               <p>
                ⑤ 在直播工具页面，点击右下角的【开始推流】。
               </p>
               <div>
                 <img src="../../assets/images/guide/use/5.png" alt="">
               </div>
          </div>



          <div class="listBox">
               <p>
                 ⑥在教研平台的直播详情页，点击【进入教研直播】。直播开始，您可进行直播了。
               </p>
               <div>
                 <img src="../../assets/images/guide/use/6.png" alt="">
               </div>
          </div>

          <!-- ----结束直播 -->
          <p style="margin-top:10px">结束直播:</p>
         
          <div class="listBox">
               <p>
                ⑦ 在直播工具右下角，您刚才点击“开始推流”处，点击【停止推流】。
               </p>
               <div>
                 <img src="../../assets/images/guide/use/7.png" style="heigth:100px" alt="">
               </div>
          </div>

          <div class="listBox">
               <p>
                ⑧ 在本平台直播间内，点击【结束直播】，直播结束。
               </p>
               <div>
                 <!-- <img src="../../assets/images/guide/use/8.png" alt=""> -->
               </div>
          </div>


           <div class="listBox">
               <p>
                注：您若需要录制直播，可在直播开始后，点击直播工具内的【开始录制】；直播结束时，点击【停止录制】。
                <br/>然后通过本平台直播详情页的【上传回放】上传录制的视频文件。（录制的视频文件可在直播工具的【设置】--【录像】--【录像路径】中显示的地址找到。）
               </p>
               <div>
                 <img src="../../assets/images/guide/use/9.1.png" alt="">
                 <img src="../../assets/images/guide/use/9.2.png" alt="">
                 <img src="../../assets/images/guide/use/9.3.png" alt="">
               </div>
          </div>

     </div>
  </div>
</template>

<script>
export default {
   data(){
       return{

       }
   }
}
</script>

<style lang="less" scoped>
  .useGuide{
      //简介
    .oneBox{
        p{
          font-size:15px;
          margin-top:10px ;
          margin-bottom:10px ;
        }
    }
    //文字图片
    .TwoBox{
      margin-top: 20px;
      //文字和图片盒子
      .listBox{
          p{
              font-size:15px;
              margin-top:10px ;
              margin-bottom:10px ;
          }
          div{
                  width: 500px;
                  // height: 150px;
                  // border: 1px solid black;
                  margin:20px;
                  img{
                      width: 100%;
                  }
          }
      }
    }
     
  }
</style>